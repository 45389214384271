import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button, Table, notification, Popconfirm, Switch, Upload, message } from 'antd';
import logo from '../../assets/pantrylogo.png'
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import axios from 'axios'
import SubmitButton from '../../Components/SubmitButton'
import moment from 'moment'

const title = "Error"

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
};

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
    if (!isJpgOrPng) {
        message.error('You can only upload csv/xlsx file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
        message.error('File must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            disable: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            tableData: [],
            allData: [],
            topicData: [],
            fileList: [],
            updateUser: false
        }
    }

    componentDidMount() {
        const { user, loginUser } = this.props
        let { tableData, topicData } = this.state
        if (!user) {
            return this.props.history.replace('/login')
        }

        console.log('user', user)

        axios.get(`https://reactbackend786.herokuapp.com/get/get-product/${user._id}`)
            .then((result) => {
                const { data } = result.data
                if (data) {
                    tableData.push({
                        key: data._id,
                        name: data.name,
                        date: data.uploadDate,
                        grocery: data.groceryName,
                        topic: data.topic.name,
                        product: data.excelFileData,
                        imageLink: data.imageLink ? 'True' : 'False',
                        index: 0
                    })
                }
                this.setState({ tableData, allData: data })
            })

        axios.get(`https://reactbackend786.herokuapp.com/get/get-user/${user._id}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    loginUser(data.user)
                    this.setState({ updateUser: true })
                }
                else {
                    return this.props.history.replace('/login')
                }
            })
        axios.get(`https://reactbackend786.herokuapp.com/admin/get/topic/${user.country}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    if (i.buSwitch) {
                        topicData.push(i.name)
                    }
                }
                topicData = topicData.filter((v, i, a) => a.indexOf(v) === i)
                this.setState({ topicData })
            })
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = e => {
        const { user, form } = this.props
        const { validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                values.topic = JSON.stringify(user.topic)
                values.groceryName = `${user.userName}, ${user.streetAddress}`
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.country = user.country
                values.uploadDate = moment().format('LL')
                values.approved = false
                var formData = new FormData();
                for (var i in values) {
                    formData.append(i, values[i])
                }
                if (values.excelFile.file) {
                    formData.set('excelFile', values.excelFile.file.originFileObj)
                }
                else {
                    formData.set('excelFile', JSON.stringify(values.excelFile))
                }
                axios.post('https://reactbackend786.herokuapp.com/post/add-product', formData)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        let { name, topic, ans1, ans2, ans3, ans4, ans5, question } = v
        setFieldsValue({
            name,
            topic,
            ans1,
            ans2,
            ans3,
            ans4,
            ans5,
            question
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }

    normFile = e => {
        this.setState({ disableUpload: false })
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            this.setState({ disableUpload: true, fileList: e.fileList })
        }
        return e && e.fileList;
    }

    deleteData = async () => {
        this.setState({ isDelete: true })
        let { tableData } = this.state
        const { user } = this.props
        await axios.post(`https://reactbackend786.herokuapp.com/post/product-delete`, { userId: user._id })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    this.setState({ tableData, isDelete: false })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                    setTimeout(() => {
                        window.location.reload()
                    }, 500)
                }
                else {
                    this.setState({ isDelete: false })
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.setState({ isDelete: false })
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    render() {
        const { user, form } = this.props
        const { getFieldDecorator } = form;
        let { sortedInfo, filteredInfo, loading, disable, tableData, isDelete } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};


        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Upload Date',
                dataIndex: 'date',
                ellipsis: true,
                ...this.getColumnSearchProps('date')
            },
            {
                title: 'Image Link',
                dataIndex: 'imageLink',
                ellipsis: true
            },
            {
                title: 'Total Products',
                dataIndex: 'product',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'product' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={this.deleteData}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ad settings</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg2">
                    <section>
                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Products</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    columns={columns}
                                    dataSource={tableData}
                                    onChange={this.handleChange}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1400, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    onSubmit={this.handleSubmit}
                                    className="login-form"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >PRODUCT UPLOAD</h1>
                                    {user.topic ? <Button
                                        style={{
                                            marginTop: 10
                                        }}
                                        type="primary"
                                        onClick={() => window.open(user.topic?.excelFile?.url, '_blank')}
                                    >
                                        Download Demo Excel
                                    </Button> : null}
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please Input Name!' }],
                                        })(
                                            <Input placeholder="Name" minLength={3} />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Image Link"
                                    >
                                        {getFieldDecorator('imageLink', {
                                            initialValue: false,
                                            rules: [{ required: true }],
                                        })(
                                            <Switch />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Upload Excel file"
                                    >
                                        {getFieldDecorator('excelFile', {
                                            rules: [{ required: true, message: 'Please Upload Excel file!' }],
                                        })(
                                            <Upload {...props}
                                                onChange={this.normFile}
                                                fileList={this.state.fileList}
                                                beforeUpload={beforeUpload}
                                                onRemove={() => this.setState({ fileList: [] })}
                                                accept=".xlsx">
                                                <Button disabled={this.state.fileList.length ? true : false} >
                                                    <Icon type="upload" /> Upload
                                            </Button>
                                            </Upload>,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <SubmitButton loading={loading} disable={disable} />
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const ProductsForm = Form.create({ name: 'normal_login' })(Products);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ProductsForm)