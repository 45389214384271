import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Input, Button, Typography, Select, Table, notification } from 'antd';
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import axios from 'axios'
import moment from 'moment'

const { Option } = Select
const { Title } = Typography;

const saleType = ["A", "B", "C"]
const title = "Error"

const data = [];
for (let i = 1; i < 46; i++) {
    data.push({
        key: i,
        name: `Q&A_00${i}`,
        topic: i,
        ans1: 'h',
        consumer: i,
        address: i > 4 ? `A_0${Math.round(i / 4) > 4 ? Math.round(Math.round(i / 4) / 4) : Math.round(i / 4)}` : `A_0${i}`
    });
}

class Subscription extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            disable: false,
            searchedColumn: '',
            tableData: [],
            subData: [],
            currentData: {
                'duration': '',
                'price': '',
                'description': ''
            }
        }
    }

    async componentDidMount() {
        const { user } = this.props
        let { tableData, subData } = this.state
        if (!user) {
            return this.props.history.replace('/login')
        }
        axios.get(`https://reactbackend786.herokuapp.com/admin/get/sub/${user.country}`)
            .then((res) => {
                const { data } = res.data
                for (var i of data) {
                    if (i.buSwitch) {
                        subData.push(i)
                    }
                }
                subData = subData.filter((v, i, a) => a.indexOf(v) === i);
                this.setState({ subData })
            })
        await axios.get(`https://reactbackend786.herokuapp.com/get/get-sub/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        name: data[i].subscription,
                        sDate: data[i].startDate,
                        eDate: data[i].endDate
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))
    }

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    changeVal(e) {
        const { subData } = this.state
        var data = subData.filter(v => v.name === e)
        this.setState({ currentData: data[0] })
    }

    handleSubmit = e => {
        const { user, form } = this.props
        const { resetFields, validateFields } = form
        const { currentData } = this.state
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                values = { ...values, ...currentData }
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.userName = user.userName
                values.userEmail = user.email
                values.country = user.country
                values.subId = currentData._id
                values.startDate = moment()
                values.endDate = moment().add(currentData.duration, 'months')
                delete values._id
                axios.post('https://reactbackend786.herokuapp.com/post/subscription', values)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let { sortedInfo, filteredInfo, currentData, loading, disable, subData, tableData } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Display Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'sDate' && sortedInfo.order
            },
            {
                title: 'End Date',
                dataIndex: 'eDate',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'eDate' && sortedInfo.order
            }
        ];
        return (
            <div>
                <div className="advertisement2">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ad settings</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg2">
                    <section>
                        <div className="center1">
                            <div className="card2">
                                <h2><b>OVERVIEW</b></h2>
                                <br />
                                <h4><b>Subscriptions</b></h4>
                                <br />
                                <br />
                                <Table
                                    className="components-table-demo-nested"
                                    onChange={this.handleChange}
                                    columns={columns}
                                    dataSource={tableData}
                                    bordered
                                    title={() => <div className="table-operations">
                                        <Button onClick={this.setAgeSort}>Sort Name</Button>
                                        <Button onClick={this.clearAll}>Clear sorters</Button>
                                    </div>}
                                    style={{ width: '100%' }}
                                    tableLayout="fixed"
                                    scroll={{ x: 1000, y: 300 }}
                                />
                            </div>
                        </div>

                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    onSubmit={this.handleSubmit}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >SUBSCRIPTIONS</h1>
                                    <Form.Item
                                        label="Subscription"
                                    >
                                        {getFieldDecorator('subscription', {
                                            rules: [{ message: 'Please Select Subscription Name!' }],
                                        })(
                                            <Select
                                                showSearch
                                                style={{ backgroundColor: '#fff' }}
                                                placeholder="Select Subscription"
                                                optionFilterProp="children"
                                                onChange={(e) => this.changeVal(e)}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {
                                                    subData.map((v, i) => {
                                                        return <Option value={v.name} key={i}>{v.name}</Option>
                                                    })
                                                }
                                            </Select>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Duration (In Months)"
                                    >
                                        {getFieldDecorator('duration')(
                                            <Title level={4}>{currentData.duration}</Title>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Price"
                                    >
                                        {getFieldDecorator('price')(
                                            <Title level={4}>{currentData.price}</Title>,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Description Subscription"
                                    >
                                        {getFieldDecorator('description')(
                                            <Title level={4}>{currentData.description}</Title>,
                                        )}
                                    </Form.Item>

                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button
                                                htmlType="submit"
                                                className="btn"
                                                loading={loading}
                                                disabled={disable}
                                                style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Pay
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>

                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const SubscriptionForm = Form.create({ name: 'normal_login' })(Subscription);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm)