import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Skeleton } from 'antd'
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'
import axios from 'axios'




class FAQ extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hitw: [],
            faqs: [],
            loading: true,
            loadingFaqs: true
        }
    }

    componentDidMount() {
        const { user } = this.props
        if (!user) {
            return this.props.history.replace('/login')
        }

        axios.post(`https://reactbackend786.herokuapp.com/admin/post/get-hitw`, {
            buSwitch2: true,
            userGroup2: user.userGroup
        })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    this.setState({ hitw: data.data, loading: false })
                }
                else {
                    this.setState({ loading: false })
                }
            })

        axios.post(`https://reactbackend786.herokuapp.com/admin/post/get-faqs`, {
            buSwitch: true,
            userGroup: user.userGroup
        })
            .then((res) => {
                const { data } = res
                if (data.success) {
                    this.setState({ faqs: data.data, loadingFaqs: false })
                }
                else {
                    this.setState({ loadingFaqs: false })
                }
            })
    }

    render() {
        const { loading, hitw, faqs, loadingFaqs } = this.state
        return (
            <div>
                <div className="advertisement2">
                </div>


                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div className="col-lg-4 col-6 d-flex justify-content-end">
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>

                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ad settings</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div>
                    <div className="main-content-bg">
                        <div id="how-work" />
                        <section className="how-works-faq">
                            <div className="container">
                                <h2>How It Works?</h2>
                                <div className="inner-box how-its-work">
                                    {loading ? <Skeleton paragraph={{ rows: 5 }} active /> : hitw.length ? hitw.map((v, i) => {
                                        return <div key={i}>
                                            <h3>{v.title}</h3>
                                            <p>{v.description}</p>
                                        </div>
                                    }) : <div>
                                            <h3>Oops Not Found!!!</h3>
                                        </div>
                                    }
                                </div>
                                <h2 id="faq">FAQ'S</h2>
                                <div className="inner-box how-its-work">
                                    {loadingFaqs ? <Skeleton paragraph={{ rows: 5 }} active /> : faqs.length ? faqs.map((v, i) => {
                                        return <div key={i}>
                                            <a data-toggle="collapse" href={`#faq-${i}`} role="button" aria-expanded="false">
                                                <div className="d-flex ques"><span> Q: </span> {v.faq}</div>
                                            </a>
                                            <p className="collapse" id={`faq-${i}`}>
                                                <span> A: </span>{v.answer}</p>
                                        </div>
                                    }) : <div>
                                            <h3>Oops Not Found!!!</h3>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer {...this.props} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(FAQ)