import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import logo from '../../assets/pantrylogo.png'
import { Form, Icon, Input, Button, Upload, Select, Table, DatePicker, Avatar, message, notification, Popconfirm } from 'antd';
import Highlighter from 'react-highlight-words';
import Footer from '../../Components/Footer'
import SubmitButton from '../../Components/SubmitButton'
import moment from 'moment'
import axios from 'axios'


const { Option } = Select
const { TextArea } = Input

const title = "Error"
var expandData = []
const saleType = ["A", "B", "C"]

const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture'
};


class Ad extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            disable: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
            StartDateValue: '',
            EndDateValue: '',
            disableUpload: false,
            tableData: [],
            fileList: [],
            allData: [],
            focusData: []
        }
    }

    async componentDidMount() {
        const { user } = this.props
        let { focusData, tableData } = this.state
        if (!user) {
            return this.props.history.replace('/login')
        }
        await axios.get(`https://reactbackend786.herokuapp.com/get/get-promotion/${user._id}`)
            .then((res) => {
                const { data } = res.data
                for (var i in data) {
                    tableData.push({
                        key: data[i]._id,
                        image: data[i].image.url,
                        name: data[i].name,
                        sDate: data[i].startDate,
                        eDate: data[i].endDate,
                        bid: data[i].bid,
                        budget: data[i].budget,
                        action: data[i],
                        index: i,
                        delete: data[i]._id
                    })
                }
                this.setState({ tableData, allData: data })
            })
            .catch((e) => console.log(e))
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    normFile = e => {
        this.setState({ disableUpload: false })
        if (e.file.type.indexOf('image')) {
            // this.openNotification(title, 'Please Upload an Image', 'close-circle', 'red')
            return
        }
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length) {
            this.setState({ disableUpload: true, fileList: e.fileList })
        }
        return e && e.fileList;
    }

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    openNotification = (title, desc, icon, color = '#108ee9') => {
        notification.open({
            message: title,
            description: desc,
            icon: <Icon type={icon} style={{ color: color }} />,
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    expandedRowRender = () => {
        const columns = [
            {
                title: 'Focus Group Names',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'fc1',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'fc2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'fc3',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [{
            fc1: expandData[0],
            fc2: expandData[1] ? expandData[1] : '',
            fc3: expandData[2] ? expandData[2] : ''
        }]
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handleSubmit = e => {
        const { form, user } = this.props
        const { resetFields, validateFields } = form
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                values.bid = Number(values.bid)
                this.setState({ disable: true, loading: true })
                values.userId = user._id
                values.userName = user.userName
                values.userEmail = user.email
                values.country = user.country
                var formData = new FormData();
                for (var i in values) {
                    formData.append(i, values[i])
                }
                formData.set('bid', JSON.stringify(values.bid))
                formData.set('budget', JSON.stringify(values.budget))
                if (values.image.file) {
                    formData.set('image', values.image.file.originFileObj)
                }
                else {
                    formData.set('image', JSON.stringify(values.image))
                }
                axios.post('https://reactbackend786.herokuapp.com/post/ad', formData)
                    .then((result) => {
                        if (result.data.success) {
                            this.openNotification('Success', result.data.message, 'check')
                            this.setState({ disable: false, loading: false })
                            resetFields()
                            window.location.reload()
                        }
                        else {
                            this.openNotification(title, result.data.message, 'close-circle', 'red')
                            this.setState({ disable: false, loading: false })
                        }
                    })
                    .catch((err) => {
                        this.openNotification(title, "OOPS Something went wrong!!!", 'close-circle', 'red')
                        this.setState({ disable: false, loading: false })
                    })
            }
        });
    };

    disabledEndDate(current) {
        return current < moment(this.state.StartDateValue)
    }

    disabledStartDate(current) {
        return current > moment(this.state.EndDateValue).add(1, 'day')
    }

    handleExpand = (row) => {
        const { allData } = this.state
        expandData = allData[row.index].focusGroup
    }

    createCopyData(v) {
        const { setFieldsValue } = this.props.form
        const { fileList } = this.state
        let { name, bid, budget, startDate, endDate, description, focusGroup } = v
        v.image.name = `${v.image.public_id}.${v.image.format}`
        v.image.thumbUrl = v.image.url
        v.image.uid = v._id
        fileList.push(v.image)
        this.setState({ fileList })
        setFieldsValue({
            name,
            bid,
            budget,
            startDate: moment(startDate),
            endDate: moment(endDate),
            description,
            image: v.image,
            focusGroup
        })
        this.openNotification('Success', 'Successfully Copy Data', 'check')
    }

    async deleteData(v) {
        let { tableData } = this.state
        await axios.get(`https://reactbackend786.herokuapp.com/del/ad/${v}`)
            .then((res) => {
                const { data } = res
                if (data.success) {
                    tableData = tableData.filter(i => i.key !== v)
                    this.setState({ tableData })
                    this.openNotification('Success', 'Successfully Deleted Data', 'check')
                }
                else {
                    this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
                }
            })
            .catch((e) => {
                this.openNotification('Error', 'Something Went Wrong!!!', 'close-circle', 'red')
            })
    }

    checkLimit(name) {
        const { getFieldValue, setFieldsValue } = this.props.form
        if (getFieldValue(name).length > 4) {
            this.openNotification('Error', 'You can only select Maximun 4!!!', 'close-circle', 'red')
            setFieldsValue({
                [name]: getFieldValue(name).slice(0, 4)
            })

            return false
        }

        return true
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        let { sortedInfo, filteredInfo, loading, disable, tableData, focusData } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'Image',
                dataIndex: 'image',
                render: (url) => <Avatar size={60} shape="square" icon="user" src={url} />
            },
            {
                title: 'Ad Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate',
                ellipsis: true,
            },
            {
                title: 'End Date',
                dataIndex: 'eDate',
                ellipsis: true,
            },
            {
                title: 'Bid/day',
                dataIndex: 'bid',
                sorter: (a, b) => a.bid - b.bid,
                sortOrder: sortedInfo.columnKey === 'bid' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('bid'),
            },
            {
                title: 'Budget',
                dataIndex: 'budget',
                sorter: (a, b) => a.name - b.name,
                sortOrder: sortedInfo.columnKey === 'budget' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('budget'),
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button onClick={() => this.createCopyData(v)}>Create</Button>
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                ellipsis: true,
                render: (v) => <Popconfirm
                    title="Are you sure delete this data?"
                    onConfirm={() => this.deleteData(v)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="danger">Delete</Button>
                </Popconfirm>
            }
        ];

        return (
            <div>
                <div className="advertisement">
                </div>

                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/product">Product</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg">
                    <section>
                        <section className="main-content-bg2">
                            <div className="center1">
                                <div className="card2">
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>Promotional Ads</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        columns={columns}
                                        dataSource={tableData}
                                        onChange={this.handleChange}
                                        // expandedRowRender={this.expandedRowRender}
                                        // onRow={(row, rowIndex) => this.handleExpand(row)}
                                        bordered
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Name</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ x: 1400, y: 300 }}
                                    />
                                </div>
                            </div>

                            <div className="center1">
                                <div className="card2">
                                    <Form
                                        className="login-form form-w"
                                        hideRequiredMark={true}
                                        onSubmit={this.handleSubmit}
                                        encType="multipart/form-data">
                                        <h1 className="heading1" >PROMOTIONAL AD SETTINGS</h1>
                                        <Form.Item
                                            label="Name"
                                        >
                                            {getFieldDecorator('name', {
                                                rules: [{ required: true, message: 'Please input Name!' }],
                                            })(
                                                <Input
                                                    type="text"
                                                    minLength={3}
                                                    placeholder="Name"
                                                />,
                                            )}
                                        </Form.Item>
                                        {/* <Form.Item
                                            label="Focusgroup"
                                        >
                                            {getFieldDecorator('focusGroup', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please Select Focusgroup Name!'
                                                    }],
                                            })(
                                                <Select
                                                    showSearch
                                                    showArrow
                                                    onSelect={() => this.checkLimit('focusGroup')}
                                                    mode="tags"
                                                    len
                                                    style={{ backgroundColor: '#fff' }}
                                                    placeholder="Select Focusgroup Name"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        focusData.map((v, i) => {
                                                            return <Option value={v} key={i}>{v}</Option>
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item> */}
                                        <Form.Item
                                            label="Bid/day"
                                        >
                                            {getFieldDecorator('bid', {
                                                rules: [{ required: true, message: 'Please input Bid/day!' }],
                                            })(
                                                <Input
                                                    type="number"
                                                    placeholder="Name"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Budget"
                                        >
                                            {getFieldDecorator('budget', {
                                                rules: [{ required: true, message: 'Please input Budget!' }],
                                            })(
                                                <Input
                                                    type="number"
                                                    placeholder="Budget"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Start Date"
                                        >
                                            {getFieldDecorator('startDate', {
                                                rules: [{ required: true, message: 'Please input Sold Price!' }],
                                            })(
                                                <DatePicker
                                                    showTime={false}
                                                    onChange={(e) => this.setState({ StartDateValue: e })}
                                                    format="YYYY-MM-DD"
                                                    disabledDate={this.disabledStartDate.bind(this)}
                                                    placeholder="Start Date"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="End Date"
                                        >
                                            {getFieldDecorator('endDate', {
                                                rules: [{ required: true, message: 'Please Select Sale Type!' }],
                                            })(
                                                <DatePicker
                                                    showTime={false}
                                                    onChange={(e) => this.setState({ EndDateValue: e })}
                                                    format="YYYY-MM-DD"
                                                    placeholder="End Date"
                                                    disabledDate={this.disabledEndDate.bind(this)}
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Description Ad-campaign"
                                        >
                                            {getFieldDecorator('description', {
                                                rules: [{ required: true, message: 'Please input Description!' }],
                                            })(
                                                <TextArea rows={4} placeholder="Input Description Here!!!" />,
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Upload Image"
                                        >
                                            {getFieldDecorator('image', {
                                                rules: [{ required: true, message: 'Please upload Image!' }],
                                            })(
                                                <Upload {...props}
                                                    onChange={this.normFile}
                                                    fileList={this.state.fileList}
                                                    onRemove={() => this.setState({ fileList: [] })}
                                                    accept="image/*">
                                                    <Button disabled={this.state.fileList.length ? true : false} >
                                                        <Icon type="upload" /> Upload
                                                    </Button>
                                                </Upload>
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <SubmitButton loading={loading} disable={disable} />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </section>
                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const AdForm = Form.create({ name: 'normal_login' })(Ad);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(AdForm)